<style lang="scss" scoped>
.line {
	margin: 0.3rem 0.3rem;
	height: 1px;
	background: $line_color;
}
::v-deep .van-cell {
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
	width: 30vw;
	flex: none;
}
::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}
::v-deep .van-field__body input {
	color: $font_color_val;
}
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}
::v-deep .van-cell__left-icon {
	display: flex;
	align-items: center;
}
.title {
	color: #000 !important;
}

.order_pay {
	position: fixed;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	.body {
		background-color: #fff;
	}
}
.tip {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.6rem 0.3rem;
	.icon {
		width: 0.6rem;
		height: 0.6rem;
		background: url(../../../assets/icon/success.png);
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;
		margin-right: 0.38rem;
	}
	.text {
		font-size: 0.3rem;
		line-height: 0.3rem;
		color: $danger;
	}
}
.title_order_info {
	margin-top: 0.3rem;
}
.order_price {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0.4rem 0.3rem 0.2rem;
	span {
		font-size: 0.24rem;
		font-weight: bold;
		line-height: 0.24rem;
	}
	.num {
		margin-right: 0.1rem;
	}
	.price {
		color: $color_active;
	}
}

.title_pay_type {
	margin-top: 0.4rem;
}
::v-deep .pay_type {
	.van-cell__left-icon {
		margin-right: 0.18rem;
		img {
			width: 0.3rem;
			height: 0.3rem;
		}
	}
	.van-cell__title {
		font-size: 0.24rem;
		color: $font_color_val;
	}
	.van-icon-success {
		border-radius: 0.06rem;
	}
}
</style>

<template>
	<div class="order_pay">
		<div class="body">
			<top-nav backImage :showBack="!isRedict" @back="$router.go(-1)" bgColor="#fff"><span class="title">订单支付</span></top-nav>
			<div class="tip">
				<span class="icon"></span>
				<span class="text">请核对订单，点击下方支付按钮完成支付！</span>
			</div>
			<active-title class="title_order_info">订单信息</active-title>
			<van-cell-group :border="false">
				<van-cell title="订单号" :value="orderInfo.billCode" title-class="cell_title" value-class="cell_value" :border="false" />
				<van-cell title="产品名称" :value="orderInfo.name" title-class="cell_title" value-class="cell_value" :border="false" />
				<van-cell title="被保人" :value="orderInfo.person" title-class="cell_title" value-class="cell_value" :border="false" />
				<van-cell title="起保日期" :value="orderInfo.enableDate" title-class="cell_title" value-class="cell_value" :border="false" />
				<van-cell title="终保日期" :value="orderInfo.disEnableDate" title-class="cell_title" value-class="cell_value" :border="false" />
				<div class="line"></div>
				<van-cell title="保单形式" :value="orderInfo.type" title-class="cell_title" value-class="cell_value" :border="false" />
				<div class="order_price">
					<span class="num" v-text="`共${orderInfo.num}份保单`"></span>
					<span>共计：</span>
					<span class="price" v-text="`￥ ${this.$base.floatPrice(orderInfo.totalPayment)}`"></span>
				</div>
				<div class="line"></div>
			</van-cell-group>

			<price-buy-btn :price="orderInfo.totalPayment" @buy="pay">立即支付</price-buy-btn>
		</div>
	</div>
</template>

<script>
import { Cell, CellGroup, RadioGroup, Radio, Toast } from 'vant';
import { getBillsInfo } from '@/request/api';
import moment from 'moment';
import { http_getInsPayUrl } from '@/request/api';

export default {
	name: 'orderPay',
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[RadioGroup.name]: RadioGroup,
		[Radio.name]: Radio,
		[Toast.name]: Toast,
	},
	props: {
		isRedict: {
			type: Boolean,
			require: true,
		},
	},
	created() {},
	mounted() {
		this.init();
	},
	data() {
		return {
			orderInfo: {
				billCode: '',
				name: '',
				person: '',
				enableDate: '',
				disEnableDate: '',
				type: '电子保单',
				num: '0',
				totalPayment: '0',
			},
			openId: '',
			lastPayTime: 0, // 最晚支付时间戳
			isReserver: '', // 是否为线下投保
			timer: null,
		};
	},
	methods: {
		init() {
			// 获取订单详情
			this.getBillInfo();
		},

		// 获取订单信息
		getBillInfo() {
			return new Promise(resolve => {
				getBillsInfo(this.$store.state.billId).then(res => {
					console.log(res);
					this.isReserver = res.list[0].is_reserver;
					// 苹果系统不支持时间横线连接符
					// this.lastPayTime = new Date(res.list[0].billInfo.payLimitTime.replace(/-/g, '/')).getTime();
					// 有在国外使用的朋友，这里要格式化为东八区时间,moment.js已经处理了苹果的兼容性
					this.lastPayTime = moment.utc(res.list[0].billInfo.payLimitTime).subtract(8, 'h').valueOf();
					this.setPageData(res.list);
					resolve();
				});
			});
		},

		// 设置页面数据
		setPageData(list) {
			let billInfo = list[0].billInfo;
			let totalPayment = 0;
			let assuredList = [];
			list.forEach(item => {
				totalPayment += Number(item.billInfo.premium);
				assuredList = [...assuredList, ...item.billAssuredList];
			});
			billInfo.totalPayment = totalPayment;

			let personNames = assuredList.map(item => item.name);
			billInfo.person = personNames.length <= 3 ? personNames.join(',') : `${personNames.slice(0, 3).join(',')}等${personNames.length}人`;
			billInfo.name = billInfo.insProductName;
			Object.assign(this.orderInfo, billInfo);
		},
		// 立即支付
		pay() {
			Toast.loading({
				duration: 0,
				forbidClick: true,
				message:"获取支付信息..."
			});

			this.timer = setInterval(async () => {
				const res = await http_getInsPayUrl(this.$store.state.billId);
				if (res.message === '200') {
					Toast.clear();
					clearInterval(this.timer);
					localStorage.setItem("billId",this.$store.state.billId)
					window.location.href=res.data
					// 201已经承保
				} else if (res.message === '400' || res.message === '201') {
					Toast.clear();
					clearInterval(this.timer);
					Toast(res.data);
					if(res.message==="201"){
						this.$router.push({name:"health_pay_success"})
					}
				}
			}, 3000);
		},
	},
};
</script>
